import { Link } from "react-router-dom";
import StaticHeader from "./StaticHeader";
import StaticFooter from "./StaticFooter";

function Howitworks() {
  return (
    <>
 <StaticHeader />
 <section id="how-we-work" class="prices padding-up text-left" style={{minHeight:"600px"}}>  
    <div class="container-fluid">
        <div class="row text-center HDRW">
            
            <div class="col-12 col-md-12 col-lg-12">
                <h3 class="heading text-white text-center">How Does RESET Work? </h3>
            </div>
        </div>
    </div>
    <div class="container reset-work">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-12">
                <span>Managing change is a process that involves a number of steps. Among these are:
                </span><br></br>
                <ul>
                <li>Identifying the negative outcomes,</li>
                <li>Discovering the problems that lead to the negative outcomes, and</li>
				<li>Determining the causes of those problems.</li>
				</ul>
                <span>The Reset assessment system was designed to assist in this effort. The assessment process consists of two steps. The first step, called the core assessment, is for the purpose of detecting the problems that are leading to the individual’s negative life outcomes. For those areas in which a problem is detected, a second assessment, called a root cause assessment, is given to the individual to identify what causes are contributing to the individual’s difficulties.
</span>
<span>Note that neither the core assessments nor the root cause assessments involve any cognitive testing. Rather, each assessment consists of a series of statements pertaining to one’s attitudes, beliefs, or behaviors. For each assessment the statements are presented, and the individual is asked to agree or disagree with each. From these responses it can be inferred to what degree the individual’s own attitudes, beliefs, and behaviors are preventing the kind of changes that are necessary to achieve or maintain the desired change.
</span>
<span>Go to the <Link to="/assessments" class="assessments-link">Assessments</Link> page to learn more about pricing and what’s included in the purchase price.</span>

            </div>
        </div>
 
    </div>
</section>
<StaticFooter />

    </>
  );
}

export default Howitworks;