import React, { useState, useEffect } from 'react';
import './Login.css';
import { loginUser } from '../../services/login';
import { Link } from 'react-router-dom';
import RightBanner from './RightBanner';
import loading from "../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";
import { Alert } from "react-bootstrap";
import { getUserProfile } from '../../services/user';
import Hide from "../../assets/images/hide.svg";
import Show from "../../assets/images/show.svg";
import logo from "../../assets/images/resetlogo.png";
import SocialLogin from './SocialLogin';




function Login(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [alertMsg, setAlertMsg] = useState(false);
    const [wrongCreds, setWrongCreds] = useState(false);
    const errMsgs = ["Username or password is incorrect!", "Internal Server Error!"]
    const [loginErrMsg, setLoginErrMsg] = useState(errMsgs[0]);
    const [errUsername, setErrUsername] = useState(false);
    const [errPassword, setErrPassword] = useState(false);
    const [showPswd, setShowPswd] = useState(false);
    const [purchaseMsg, setPurchaseMsg] = useState(false);

    useEffect(() => {
        const packageId = localStorage.getItem('selectedPackageId');
        if(packageId){
            setPurchaseMsg(true);
        }
        
      }, []);
      
    const showPswdHandler = () => {
        setShowPswd(!showPswd);
    }


    var usernameInp, passwordInp;

    useEffect(() => {
        usernameInp = document.getElementById("username-input");
        passwordInp = document.getElementById("password-input");
    });



    const validateFields = () => {
        let pass = true;
        if (username.trim() == "") {
            setErrUsername(true);
            usernameInp.classList.add("error-input-border");
            pass = false;
        }
        if (password == "") {
            setErrPassword(true);
            passwordInp.classList.add("error-input-border");
            pass = false;
        }
        return pass;
    }

    const onChangeUsername = (val) => {
        if (errUsername == true) {
            usernameInp.classList.remove("error-input-border");
            setErrUsername(false);
        }
        setUsername(val);
    }
    const onChangePassword = (val) => {
        if (errPassword == true) {
            passwordInp.classList.remove("error-input-border");
            setErrPassword(false);
        }
        setPassword(val);
    }


    const handleSubmit = async e => {
        e.preventDefault();
        if (!validateFields()) {
            return;
        } else {
            setAlertMsg(true);
            try {
                const response = await loginUser({
                    username,
                    password
                });
                if (response.status) {
                    localStorage.setItem("staticScreen","N");
                    setAlertMsg(false);
                    props.token(response.data);
                    props.status("Y");
                    setLoginErrMsg(null);
                    setWrongCreds(false);
                    setUsername("");
                    setPassword("");
                } else {
                    setLoginErrMsg(errMsgs[0]);
                    setWrongCreds(true);
                    setAlertMsg(false);
                }
            } catch (error) {
                setLoginErrMsg(errMsgs[1]);
                setWrongCreds(true);
                setAlertMsg(false);
            }
        }

    }


    return (
        <>
            <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-2 right-text text-center">

                    <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                        <Link to="https://whatisreset.com" title="Logo" className="logo">
                            <img src={logo} title="logo" alt="logo" className="logo-default" />
                        </Link>
                        <div className="w-lg-500px p-10">
                        <Alert className="login-alerts" show={purchaseMsg} onClose={() => setPurchaseMsg(false)} variant="success" dismissible>
                                Please Sign In to Purchase Package
                            </Alert>
                            <Alert className="login-alerts" show={wrongCreds} onClose={() => setWrongCreds(false)} variant="danger" dismissible>
                                {loginErrMsg}
                            </Alert>
                            {alertMsg ?
                                <SweetAlert
                                    title=""
                                    timeout={10000}
                                    showConfirm={false}
                                    style={{ width: "200px", height: "100px" }}
                                > <img src={loading} width="50px" height="50px" />
                                </SweetAlert>
                                : <></>}

                            <form className="form w-100" id="kt_sign_in_form" onSubmit={handleSubmit}>
                                <div className="text-center mb-11">
                                    <h1 className="text-gray-900 fw-bolder mb-3">
                                        Sign In
                                    </h1>
                                </div>
                                <div className="fv-row mb-2">
                                    <input type="text" value={username} id="username-input"
                                        onChange={(e) => onChangeUsername(e.target.value)}
                                        placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent" />
                                    <span className="input-pre-text"><small>&ensp;</small></span>
                                    {errUsername == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Username.</small></span> : null}
                                </div>

                                <div className="fv-row mb-2">
                                    <input type={showPswd ? "text" : "password"} value={password} id="password-input"
                                        onChange={(event) => onChangePassword(event.target.value)} placeholder="Password" name="password" autoComplete="off" className="form-control bg-transparent" />
                                    <div className="show-hide-pswd-eye" onClick={() => { showPswdHandler() }}>
                                        {showPswd ? <img src={Hide} /> : <img src={Show} />}
                                    </div>
                                    <span className="input-pre-text"><small>&ensp;</small></span>
                                    {errPassword == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Password.</small></span> : null}
                                </div>

                                <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                    <div></div>

                                    <Link to="/forgot-password" className="link-primary">
                                        Forgot Password ?
                                    </Link>
                                </div>

                                <div className="d-grid mb-2">
                                    <button type="submit" id="kt_sign_in_submit" className="btn btn-primary">

                                        <span className="indicator-label">
                                            Sign In</span>

                                        <span className="indicator-progress">
                                            Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                               <div className='text-center'>
                                    <b>Or</b>
                                </div>
                                <SocialLogin />
                                <div className="text-gray-500 text-center fw-semibold fs-6 mt-30">
                                    Not a Member yet?
                                    <Link to="/signup" className="btn btn-small btn-primary ml-10">
                                        SignUp
                                    </Link>
                                    {/*<span className="link-primary"> / </span>
        <Link to="/expert-signup" className="link-primary">
            Expert
                </Link>*/}
                                </div>
                                {/*<hr />
        <div className="text-center fw-semibold fs-6 signup-mt">
        <Link to="/signup" className="link-primary signup-link">
            User SignUp
        </Link>
        </div>*/}
                                <Link to="/expert-signup" className="link-primary">
                                    <div className="text-center fw-semibold fs-6 expert-signup-btn">
                                        Expert SignUp
                                    </div>
                                </Link>


                            </form>
                        </div>
                    </div>


                </div>

                <RightBanner />
            </div>
        </>
    )
}

export default Login;
