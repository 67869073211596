import { Link } from "react-router-dom";
import StaticHeader from "./StaticHeader";
import StaticFooter from "./StaticFooter";

function Distinctiveness() {
  return (
    <>
 <StaticHeader />
 <section class="hosting bg-light-white text-left" id="our-distinctiveness" style={{minHeight:"600px"}}>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-12">
                <div class="heading-area">
                    <h2 class="heading mb-0">How Is RESET Different & The Best ? </h2>
                    <br></br>
                        <p class="text-black mt-8">Reset is not a personality test or a cognitive assessment, and it does not produce a numeric score. It uses artificial intelligence to identify attitudes, opinions, and beliefs that block individual growth and improvement in areas such as wellbeing, life skills, and employability.</p>
                        <p class="text-black">Reset is not a single assessment. It incorporates a two-step process that first identifies the negative outcomes being experienced by the individual, then performs a deep-dive into the root causes of the individual’s failure to make the changes necessary to achieve more positive outcomes.</p>
                        <p class="text-black">Reset assessments are assembled by a team of highly experienced psychologists and assessment experts employing state of the art psychometrics. Based on the most widely researched and validated models and theories of the psychosocial determinants of behavior, they address factors such as environmental mastery, safety, self-actualization, self-efficacy, and grit.</p>
                        <p class="text-black">Reset produces reports with specific recommendations as to the changes in attitudes and beliefs the individual needs to make and identifies resources that can help.</p>
                        <p class="text-black">Reset does not require any psychological, counseling, or assessment expertise on the part of the user or professional provider; nor does the user or provider need to know anything about artificial intelligence. Benefits to the provider from using this highly automated assessment system include enhanced work efficiency, reduced workload, improved quality of services, enhanced client engagement, and improved client outcomes.</p>
                </div>
            </div>
           
        </div>
    </div>
</section>
<StaticFooter />

    </>
  );
}

export default Distinctiveness;