import { Link } from "react-router-dom";
import StaticHeader from "./StaticHeader";
import StaticFooter from "./StaticFooter";
import about from "../../assets/static/img/reset.svg";


function About() {
  return (
    <>
 <StaticHeader />
 <section id="what-is-reset" class="bg-light-white">
    <div class="container">
     <div class="row align-items-center">
           <h1 class="col-md-12 heading">What is RESET?</h1>
         </div>
        <div class="row align-items-center mt-15">
            <div class=" col-sm-12 col-md-4 img-sec mt-5 mt-md-0 wow fadeIn order-2 order-md-1 left-image" data-wow-duration="1.5s" data-wow-delay=".5s">
                <div class="blue_rectangle"></div>
                <div class="about_img position-relative">
                    <img src={about} alt="About Image" />
                </div>
            </div>
            <div class="col-sm-12 col-md-8 pl-md-5 wow fadeInleft order-1 order-md-1 right-text" data-wow-duration="1.5s" data-wow-delay=".5s">
               <div class="about-heading text-justify">
                    <span>Everyone deserves the opportunity for growth.</span><br></br>
		    <span>Reset helps uncover the root causes that block progress. Our deep understanding of those unique challenges unlocks pathways to a better life while building on each person’s strengths and goals.</span>
                    <span>Our mission is to help people change and grow, by identifying the things that are holding them back, and enabling qualified professionals to remediate the relevant root causes of (in)action.</span>               
	            <span class="head"><b>What is it?</b></span>
		    <span>Reset is an assessment system designed to identify the root causes that prevent people from changing, growing, and succeeding. This is accomplished through an artificial intelligence driven, two-step process designed to first identify problem areas through a set of broadly defined core assessments, then uncover the causes of the problem using a root cause analysis performed on data collected on more narrowly defined, targeted assessments.</span>
		    <span class="head"><b>Who uses it?</b></span>
		    <span>It is intended for use by individual users wishing to discover and address the obstacles preventing their efforts to self-improve, and for employment counselors, life coaches, nutritionists, psychological and psychiatric practitioners, educators, and others attempting to help their clients’ efforts to self-improve.</span>
	       </div>
            </div>
        </div>
    </div>
</section>
<StaticFooter />

    </>
  );
}

export default About;