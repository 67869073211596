import { useEffect, useState } from "react";
import { getDashboardDetails } from "../../../services/user";
import AssesementPackages from "../Packages/AssesmentPackages";

function UserDashboard() {
    const [purchased, setPurchased] = useState(0);
    const [progress, setProgress] = useState(0);
    const [completed, setCompleted] = useState(0);
    const userType = sessionStorage.getItem('userType');
    const getDetails = () => {
        getDashboardDetails().then((res) => {
            if (res.data.data){
                setPurchased(res.data.data.purchased);
                setProgress(res.data.data.progress);
                setCompleted(res.data.data.completed);
            }
                
        })
    }
    useEffect(() => {
      getDetails();
    }, [])


    return (
        <>
       <div className="card card-xxl-stretch mb-5 mb-xl-10">
    <div className="card-header card-header-stretch overview-card">
        <div className="card-title" style={{color:"#071437"}}>
            <h3 className="m-0 text-gray-900">Package Summary</h3>
        </div>
    </div>

    <div className="card-body pt-7 pb-0 px-0">
        <div className="tab-content">
            <div className="tab-pane fade active show" id="kt_security_summary_tab_pane_hours" role="tabpanel">
                <div className="row p-0 mb-5 px-9">
                    <div className="col">
                        <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                            <span className="fs-4 fw-semibold text-success d-block">{userType === "U" ? "Purchased" : "Assigned"}</span>
                            <span className="fs-2hx fw-bold text-gray-900" data-kt-countup="true" data-kt-countup-value="36899">{purchased}</span>
                        </div>
                    </div>

                    <div className="col">
                        <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                            <span className="fs-4 fw-semibold text-primary d-block">In Progress</span>
                            <span className="fs-2hx fw-bold text-gray-900" data-kt-countup="true" data-kt-countup-value="72">{progress}</span>
                        </div>
                    </div>

                    <div className="col">
                        <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                            <span className="fs-4 fw-semibold text-danger d-block">Completed</span>
                            <span className="fs-2hx fw-bold text-gray-900" data-kt-countup="true" data-kt-countup-value="291">{completed}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    </>
    )
}

export default UserDashboard;