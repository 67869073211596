import React, { useState, useEffect } from 'react';
import StaticHeader from "./StaticHeader";
import StaticFooter from "./StaticFooter";
import { saveContact } from "../../services/contact";
import SweetAlert from "react-bootstrap-sweetalert";
import { Alert } from "react-bootstrap";
import loading from "../../assets/images/loader_grey.gif";

function ContactUs() {

  const [name, setName] = useState("");
  const [mobileno, setMobileno] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errMsg, setErrMsg] = useState();
  const [errName, setErrName] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [errEmail, setErrEmail] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState();

  var nameInp, messageInp, emailInp;

  useEffect(() => {
    nameInp = document.getElementById("name-input");
    messageInp = document.getElementById("message-input");
    emailInp = document.getElementById("email-input");
  });

  const validateFields = () => {
    if (name === "") {
      setErrName(true);
      nameInp.classList.add("error-input-border");
      return false;
    }
    if (message == "") {
      setErrMessage(true);
      messageInp.classList.add("error-input-border");
      return false;
    }
    if (email == "") {
      setErrEmail(true);
      emailInp.classList.add("error-input-border");
      return false;
    }
    return true;
  }

  const onChangeName = (val) => {
    if (errName == true) {
      nameInp.classList.remove("error-input-border");
      setErrName(false);
    }
    setName(val);
  }
  const onChangeMobileno = (val) => {
    setMobileno(val);
  }
  const onChangeMessage = (val) => {
    if (errMessage == true) {
      messageInp.classList.remove("error-input-border");
      setErrMessage(false);
    }
    setMessage(val);
  }

  const onChangeEmail = (val) => {
    if (errEmail == true) {
      emailInp.classList.remove("error-input-border");
      setErrEmail(false);
    }
    setEmail(val);
  }


  const handleSubmit = async e => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    } else {
      try {
        setAlertMsg(true);
        const response = await saveContact({ name, email, mobileno, message });
        if (response.status) {
          setAlertMsg(false);
          setName("");
          setMobileno("");
          setMessage("");
          setEmail("");
          setAlertError(false);
          setAlertSuccess(true);
          setSuccessMsg("Your message has been sent. Thank you!");
          setTimeout(() => {
            setAlertSuccess(false);
          }, 3000);
      
        } else {
          setAlertMsg(false);
          setErrMsg(response.message);
        }
      } catch (error) {
        setErrMsg("Internal Server Error");
        setAlertMsg(false);
      }

    }

  }


  return (
    <>
      <StaticHeader />
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">

          <div class="section-title">
            <h2>Contact Us</h2>
            <p style={{ color: "black" }}>For more information about Reset, use the form below.</p>
          </div>

          <div class="row">

            <div class="col-lg-5 d-flex align-items-stretch">
              <div class="info">
                <div class="address">
                  <i class="fas fa-map-marker" aria-hidden="true"></i>
                  <h4>Location:</h4>
                  <p>5037 N Crestridge Dr Tucson Az 85718</p>
                </div>

                <div class="email">
                  <i class="fas fa-envelope"></i>
                  <h4>Email:</h4>
                  <p>Support@whatisreset.com</p>
                </div>

              </div>

            </div>

            <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
            {alertMsg ?
                                <SweetAlert
                                    title=""
                                    timeout={10000}
                                    showConfirm={false}
                                    style={{ width: "200px", height: "100px" }}
                                > <img src={loading} width="50px" height="50px" />
                                </SweetAlert>
                                : <></>}
              <form id="contactForm" class="contact-form" onSubmit={handleSubmit}>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="name">Name<span class="error">*</span></label>
                    <input type="text" name="name" class="form-control mb-0" value={name} id="name-input"
                                        onChange={(e) => onChangeName(e.target.value)} />
                       <span className="input-pre-text"><small>&ensp;</small></span>
                       {errName == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Name.</small></span> : null}                  
                  </div>
                  <div class="form-group col-md-6">
                    <label for="name">Email<span class="error">*</span></label>
                    <input type="email" class="form-control mb-0" name="email" value={email} id="email-input"
                                        onChange={(e) => onChangeEmail(e.target.value)}/>
                     <span className="input-pre-text"><small>&ensp;</small></span>
                     {errEmail == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Email.</small></span> : null}
                  </div>
                </div>
                <div class="form-group">
                  <label for="name">Mobile No</label>
                  <input type="text" class="form-control" name="mobileno" value={mobileno} id="mobileno-input"
                                        onChange={(e) => onChangeMobileno(e.target.value)} />
                </div>
                <div class="form-group">
                  <label for="name">Message<span class="error">*</span></label>
                  <textarea class="form-control mb-0" name="message" rows="3" value={message} id="message-input"
                                        onChange={(e) => onChangeMessage(e.target.value)}></textarea>
                   <span className="input-pre-text"><small>&ensp;</small></span>
                   {errMessage == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Message.</small></span> : null}
                </div>
                <Alert className="login-alerts"
                   show={alertSuccess} onClose={() => setAlertSuccess(false)} variant="success" dismissible>
                                {successMsg}
                            </Alert>
                <div class="text-center"><button class="btn btn-primary" type="submit">Send Message</button></div>
              </form>
            </div>

          </div>

        </div>
      </section>
      <StaticFooter />

    </>
  );
}

export default ContactUs;