import React from 'react';
import logo from "../../assets/images/resetlogo.png";
import '../Login/Login.css';


function UserPackagePurchased() {

    const goToPackage = async () => {
        localStorage.clear();
        window.location.href = '/packages';
    };
    
   
    return (
        <>
            <div id="kt_app_toolbar" class="py-3 py-lg-6 ">
            <div id="kt_app_toolbar_container" className="container-xxl d-flex flex-stack ">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                        <img src={logo} title="logo" alt="logo" className="logo-default" />
                        </h1>
                       
                    </div>
                </div>
                <div className="container text-center">
            <div className="text-center mb-30 mt-8">
             <h1>Seems you have already purchased this package.</h1>
             </div>
                <div class="mt-5 mb-80 mt-8">
                     <button type="reset" class="btn btn-sm fw-bold btn-primary me-2 ml-30" onClick={goToPackage}>Go to Packages</button>
                </div>
                </div>
            </div>
        </>
    );
}

export default UserPackagePurchased;
