import { SAVE_CONTACT } from "../Constants";

export function saveContact(details) {
 return fetch(SAVE_CONTACT, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(details)
 })
   .then(data => data.json())
}

 
