import React, { useEffect, useState } from "react";
import StaticHeader from "./StaticHeader";
import StaticFooter from "./StaticFooter";
import slide from "../../assets/static/img/slide_new.webp";
import slideLow from "../../assets/static/img/slide_low.webp";
import { Helmet } from "react-helmet";


function Home() {
    const [imageLoaded, setImageLoaded] = useState(false);
    useEffect(() => {
        // Preload image when the component mounts
        const img = new Image();
        img.src = slide;
        img.onload = () => setImageLoaded(true);
      }, [slide]);
  return (
    <>
 <StaticHeader />
 <Helmet>
        <link
          rel="preload"
          href={slide}
          as="image"
        />
      </Helmet>
<section id="particles-js" class="p-0 position-relative" style={{
    justifyContent: "center",width: "100%",backgroundImage: imageLoaded ? "url(" + slide + ")" : "url(" + slideLow + ")",
    backgroundSize: "cover",backgroundPosition: "center",backgroundRepeat: "no-repeat",
    }}>
    <div class="slider-area" id="slider-area">
        <div class="container">
            <div class="row align-items-center">
                 <div class="col-12 col-md-12 col-lg-12 text-right image-order area-heading">
                    <h2 class="main-font text-white font-weight-bold mb-4 mt-30">Sometimes change is necessary</h2>
                </div>
                  <div class="col-12 col-md-6 col-lg-6 text-right image-order">
                    &nbsp;
                </div>
                <div class="col-12 col-md-6 col-lg-6 text-md-center text-lg-left content-margin pl-0">
                    <div class="area-heading text-center text-lg-left">
                        <p class="text-white alt-font mb-5">•	Managing impulsive spending,<br></br>
                        •	Sticking to a budget or spending plan,<br></br>
                        •	Learning new skills to obtain or maintain better employment, or<br></br>
                        •	Reduction of stress and anxiety. </p>
                    </div>
                </div>
   <div class="col-12 col-md-6 col-lg-6 text-right image-order" >
                    &nbsp;
                </div>
                <div class="col-12 col-md-6 col-lg-6 text-md-center text-lg-left content-margin pl-0">
                    <div class="area-heading text-center text-lg-left">
                        <h2 class="main-font text-white font-weight-bold mb-4">But change can be hard </h2>
                        <p class="text-white alt-font mb-5">•	Millions of people fail to acquire the basic skills necessary for any job,<br></br>
                        •	Nearly three quarters of people fail to stick to any kind of budget,<br></br>
                        •	More than half of all purchases are impulsive buys, and<br></br>
                        •	More than 20% of people carry a balance on their credit card month to month. </p>
                        <a href="#aboutreset" class="scroll btn btn-medium btn-rounded btn-trans-white mb-5">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="aboutreset" class="about-reset text-left">
<p>Whether you’re trying to be the best you can be, or a professional provider trying to help your clients be the best they can be, Reset can help.</p>
<p><b>Individual users,</b> are you having trouble sticking to a diet or exercise plan? Getting a better job or finally achieving financial security? Or maybe just trying to gain a sense of well-being? Sign up as an individual user and let Reset help you discover what’s holding you back, and match you to the resources you need to finally be the person you’ve always wanted to be.</p>
<p><b>Providers,</b> do you have a client who knows what needs to be done but just won’t do it? Or maybe a client who won’t open to you about what’s holding them back? Sign up as an expert user and let our Reset assessments reveal the obstacles that are holding them back.</p>
</section>
<StaticFooter />

    </>
  );
}

export default Home;