import React, { useState, useEffect } from 'react';
import '../Login/Login.css';
import { getPackageList } from '../../services/userpackage';
import UserPackagePurchased from './UserPackagePurchased';
import UserPackageSummary from './UserPackageSummary';


function UserPackage() {

    const [packageList, setPackageList] = useState();
    const [purchased, setPurchased] = useState(false);
    const themeId = localStorage.getItem('selectedThemeId');
    const packageId = localStorage.getItem('selectedPackageId');
    const userType = sessionStorage.getItem('userType')
    useEffect(() => {
      if(userType === 'E' || userType === 'EU'){
        localStorage.clear();
        window.location.href = '/overview';
      }else{
        getPackages();
      }
      }, []);
      const getPackages = async () => {
        try {
          const response = await getPackageList(themeId);
          setPackageList(response.data.data);
          const foundItem = response.data.data.find(item => item.packageId == packageId);
          setPurchased(foundItem.purchased); 
        } catch (error) {
          setPackageList(null);
          console.error(error);
        }
      };
   
    return (
        <>
           {purchased ? <UserPackagePurchased /> : <UserPackageSummary />}
        </>
    );
}

export default UserPackage;
