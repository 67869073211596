import React, { useState, useEffect } from 'react';
import { getThemeList } from '../../services/userpackage';
import apiToken from '../../apiToken';
import { Link } from 'react-router-dom';
import AssessmentToolBar from './AssessmentToolBar';
import { getExchangeRate } from '../../services/user';


function Themes(props) {

  const [themeList, setThemeList] = useState();
  const [left, setLeft] = React.useState([]);
  const [continueStatus, setContinueStatus] = useState(false);
  useEffect(() => {
    getTheme();
    if (!sessionStorage.getItem('exchangeRate')) {
      getRate();
       }
  }, []);
  const getTheme = async () => {
    try {
      const response = await getThemeList();
      setThemeList(response.data.data);

    } catch (error) {
      setThemeList(null);
      console.error(error);
    }
  };
  const getRate = async () => {
    try {
        const response = await getExchangeRate();
        let userData = response.data;
        if(userData.data!=null){
          if(userData.data.exchangeRate!=null){
            sessionStorage.setItem('currencyCode', userData.data.currencyCode); 
            sessionStorage.setItem('exchangeRate', userData.data.exchangeRate); 
        }
        }
    } catch (error) {
        console.error(error);
    }
};
  const getPage = (page) => {
    props.page(page);
  };

  const selectTheme = (val) => {
    setContinueStatus(true);
    props.themeId(val);
  }

  return (
    <>
              <AssessmentToolBar title={"Themes"} page={getPage}
                isPurchase={false} continueStatus={continueStatus} />
              <div id="kt_app_content" className="app-content  flex-column-fluid" >

                <div id="kt_app_content_container" className="container-xxl ">
                  <h3 className="card-title align-items-start flex-column mb-10">
                    <span className="card-label">Select Theme</span>
                  </h3>
                  <div className="row row-cols-lg-4  row-cols-1 row-cols-sm-2 row-cols-md-3 g-5 g-xl-8">
                    {(themeList ? themeList : left).map((value) => (
                      <div className="col">
                        <div className="card card-1 inputGroup mb-3">
                          <input className="form-check-input" value={value.themeId}
                            onChange={(event) => selectTheme(event.target.value)} type="radio" name="flexRadioDefault" id={value.themeId} />
                          <label className="form-check-label"
                            style={{
                              backgroundImage: "url(" + value.themeImageUrl + ")",
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat'
                            }} htmlFor={value.themeId}></label>
                        </div>
                        <h2>{value.themeName}</h2>
                      </div>
                    )
                    )}
                  </div>

                </div>
              </div>

    </>
  );
}

export default Themes;