import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import logo from "../../assets/images/resetlogo.png";
import { useLocation } from 'react-router-dom'
import "../../assets/static/css/lineIcons.css";
import "../../assets/static/css/custom.css";

function StaticHeader() {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation().pathname;
  const openMenu = () => {
    setShowMenu(true);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };
   localStorage.setItem("staticScreen","Y");
    const userStatus = sessionStorage.getItem("userStatus");
    const login = () => {
      localStorage.setItem("staticScreen","N");
     if(userStatus == 'A'){
      window.location.href = '/overview';
     }else{
      window.location.href = '/login';
     }
    };
  return (
    <>
      <header>
      <nav className="navbar navbar-top-default navbar-expand-lg navbar-simple">
          <Link to="/" title="Logo" className="logo scroll">
            <img src={logo} title="logo" alt="logo" className="logo-default" />
          </Link>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className={location === "/" ?"nav-item active" : "nav-item"}>
                <Link className="nav-link line" to="/">Home</Link>
              </li>
              <li className={location === "/about" ?"nav-item active" : "nav-item"}>
                <Link className="nav-link line" to="/about">What is Reset</Link>
              </li>
              <li className={location === "/howitworks" ?"nav-item active" : "nav-item"}>
                <Link className="nav-link line" to="/howitworks">How It Works</Link>
              </li>
              <li className={location === "/themes" || location === "/theme/packages" ?"nav-item active" : "nav-item"}>
                <Link className="nav-link line" to="/themes">Assessments</Link>
              </li>
              <li className={location === "/distinctiveness" ?"nav-item active" : "nav-item"}>
                <Link className="nav-link line" to="/distinctiveness">Our Distinctiveness</Link>
              </li>
              <li className={location === "/team" ?"nav-item active" : "nav-item"}>
                <Link className="nav-link line" to="/team">Team</Link>
              </li>
              <li className={location === "/contactus" ?"nav-item active" : "nav-item"}>
                <Link className="nav-link line" to="/contactus">Connect</Link>
              </li>
              <li className="nav-item">
                <a className="nav-link line" href="https://whatisreset.com/blog">Blog</a>
              </li>
            </ul>
            <div className="form-inline my-2 my-lg-0">
              <button className="btn btn-primary my-2 my-sm-0" onClick={login}>{userStatus==='A'?"Go To App":"SignUp/Login"}</button>
            </div>
          </div>
        <div class="navigation-toggle">
            <div class="slider-social toggle-btn my-0">
                    <a href="javascript:void(0);" aria-label="menu" onClick={openMenu} class="sidemenu_btn" id="sidemenu_toggle">
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
            </div>
        </div>
        </nav>
        {showMenu ?
          <>
           <div class="side-menu side-menu-active">
           <div class="inner-wrapper">
           <span class="btn-close" id="btn_sideNavClose" onClick={closeMenu}></span>
                <Link to="/" title="Logo" className="logo" onClick={closeMenu}>
                  <img src={logo} title="logo" alt="logo" className="logo-default" />
                </Link>
              
            <nav class="side-nav w-100 mt-30">
                <ul class="navbar-nav side-navbar">
                    <li class={location === "/" ?"nav-item active" : "nav-item"}>
                        <Link class="nav-link" to="/">Home</Link>
                    </li>
                    <li class={location === "/about" ?"nav-item active" : "nav-item"}>
                        <Link class="nav-link" to="/about">What is Reset</Link>
                    </li>
                    <li class={location === "/howitworks" ?"nav-item active" : "nav-item"}>
                        <Link class="nav-link" to="/howitworks">How It Works</Link>
                    </li>
                    <li class={location === "/themes" || location === "/theme/packages" ?"nav-item active" : "nav-item"}>
                        <Link class="nav-link" to="/themes">Assessments</Link>
                    </li>
                    <li class={location === "/distinctiveness" ?"nav-item active" : "nav-item"}>
                        <Link class="nav-link" to="/distinctiveness">Our Distinctiveness</Link>
                    </li>
                    <li class={location === "/team" ?"nav-item active" : "nav-item"}>
                        <Link class="nav-link" to="/team">Team</Link>
                    </li>
                    <li class={location === "/contactus" ?"nav-item active" : "nav-item"}>
                        <Link class="nav-link" to="/contactus">Connect</Link>
                    </li>
  <li class="nav-item">
        <a class="nav-link" href="https://whatisreset.com/blog">Blog</a>
      </li>
                     <li class="nav-item">
                        <button class="btn btn-primary" onClick={login}>{userStatus==='A'?"Go To App":"SignUp/Login"}</button>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <a id="close_side_menu" href="javascript:void(0);" style={{display: "inline"}}></a> 
          </> : ""}
       
      </header>


    </>
  );
}

export default StaticHeader;