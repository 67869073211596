import React, { useState, useEffect } from 'react';
import StaticHeader from "./StaticHeader";
import StaticFooter from "./StaticFooter";
import { API_BASE_URL, GET_PACKAGE_LIST } from '../../Constants';
import loading from "../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";

function Packages() {
  const themeId = localStorage.getItem('selectedThemeId');
  const themeName = localStorage.getItem('themeName');
  const description = localStorage.getItem('description');
  const [alertMsg, setAlertMsg] = useState(false);
  const [packageList, setPackageList] = useState();
  const [left, setLeft] = React.useState([]);

  useEffect(() => {
    localStorage.removeItem("selectedPackageId");
    setAlertMsg(true);
    fetch(API_BASE_URL + GET_PACKAGE_LIST + themeId)
      .then((response) => {
        setAlertMsg(false);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setAlertMsg(false);
        setPackageList(data.data)
      })
      .catch((error) => {
        setAlertMsg(false);
        setPackageList(null);
        console.error(error);
      });
  }, []);

  const selectPackage = (item) => {
    localStorage.setItem('selectedPackageId', item.packageId);
    localStorage.setItem('staticScreen', "N");
    window.location.href = '/login';
  }
  return (
    <>
      <StaticHeader />
      <section class="services sections-bg">

        <div class="container aos-init aos-animate" data-aos="fade-up" id="packages">

          <div class="section-header text-center" id="themeTitle">
            <h1 class="heading">Our {themeName} Packages</h1>
            <p><div dangerouslySetInnerHTML={{ __html: description }}></div></p>
          </div>
          {alertMsg ?
                <SweetAlert
        title=""
        timeout={10000}
        showConfirm={false}
        style={{ width: "200px", height: "100px" }}
      > <img src={loading} width="50px" height="50px"/>
      </SweetAlert>
      :<></>}
          <div class="row gy-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100" id="packageDiv">
            {(packageList ? packageList : left).map((value,index) => (
              <div class="col-lg-4 col-md-6 mt-30">
                <div class="card service-item">
                  <div style={{height:"200px"}}>
                    <img class="card-img-top img-fluid" src={value.packageImageUrl} alt="Card image" style={{ width: "100%" }} />
                  </div>
                  <div class="card-body">
                    <h3 class="packageHead">{value.packageName}</h3>
                    <p class="card-text"><div dangerouslySetInnerHTML={{ __html: value.description }}></div></p>
                  </div>
                  <div class="card-footer text-muted">
                    <span class="price-text">
                      {value.isFree === 'N' ? '$'+value.price : "" }
                    </span>
                    <button key={index} onClick={() => selectPackage(value)} class="btn btn-small btn-primary float-right radius-20">Buy Now</button>
                  </div>
                </div>
              </div>
            )
            )}

          </div>

        </div>
      </section>
      <StaticFooter />

    </>
  );
}

export default Packages;