import StaticHeader from "./StaticHeader";
import StaticFooter from "./StaticFooter";
import profile from "../../assets/static/img/profile.jpeg";
import robert from "../../assets/static/img/robert.png";
import julian from "../../assets/static/img/julian.png";

function Team() {
  return (
    <>
 <StaticHeader />
 <section class="blog-sec" id="team">
    <div class="container">
        <div class="row text-center">
            <div class="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2 heading-area">
                <h3 class="heading text-center">Meet The Team</h3>
            </div>
        </div>
        <div class="row wow fadeInUp">
            <div class="col-lg-6">
                <div class="news-item">
                    <div class="news-text-box"> 
					<a class="author d-flex align-items-center" href="javascript:void(0);">
					<img alt="image" class="author-img bg-blue" src={robert} />
					 <div class="text-black"> 
                            <h5 class="news-title">Dr. Robert McKinley</h5>
                            <p>Founder & CEO</p>
							</div>
							</a>
                        <hr></hr>
                        <p class="para mb-10">Robert is an internationally recognized leader in statistics and measurement theory, and a pioneer in the field of artificial intelligence. With over forty years of experience in the fields of assessment, educational psychology, and health related research, Robert is uniquely qualified to address the challenges faced by all individuals attempting to make beneficial changes in their lives, as well as business and organizational leaders attempting to bring about changes in their products, services, or operational methods, or in the attitudes or skillsets of their employees.
                        </p><p class="para mb-10">
                       Robert has published numerous articles and research reports on various aspects of Item Response Theory, adaptive testing, and the health belief model. He has held both technical and leadership roles in assessment giants ETS and ACT. In addition to his contributions to computerizing the ASVAB and implementing adaptive testing in the naval training programs, he is the inventor of both the patented scripted testing adaptive testing algorithm employed for the GMAT and the testlet-based adaptive testing algorithm employed for the Kansas Interim Assessment.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="news-item">
                    <div class="news-text-box"> 
					<a class="author d-flex align-items-center" href="javascript:void(0);">
					<img alt="image" class="author-img bg-blue" src={profile} />
					 <div class="text-black"> 
                            <h5 class="news-title">Tama Braswell</h5>
                            <p>Chief Data Scientist</p>
							</div>
							</a>
                        <hr></hr>
                        <p class="para mb-10">Tama is a nationally recognized leader in the fields of systems engineering and data science, with expertise in linear and nonlinear data modeling, artificial intelligence, Bayesian classification, and applications of the EM algorithm. Her unique talent for understanding, managing, analyzing, and interpreting complex and evolving data has placed her in the forefront of the field of data science.
                        </p><p class="para mb-10">
                       Tama has held leadership positions in several assessment organizations, including Principal Systems Engineer at ACT and Data Scientist at Learning A-Z, and is an expert in SAS. In her previous roles at ETS, Pearson, and ACT she pioneered the development of automated procedures for adaptive testing pool development, item exposure control, differential item functioning, quality assurance and test security measures, model fit, scale drift, and latent ability distribution estimation and equating.
                       </p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-12">
                <div class="news-item">
                    <div class="news-text-box"> 
					<a class="author d-flex align-items-center" href="javascript:void(0);">
					<img alt="image" class="author-img bg-blue" src={julian} />
					 <div class="text-black"> 
                            <h5 class="news-title">Julian L. Alssid</h5>
                            <p>Strategic Planning & Business Development</p>
							</div>
							</a>
                        <hr></hr>
                        <p class="para mb-10">Julian is a nationally recognized strategist who has been at the leading edge of education and workforce development for over 35 years. Having held various leadership positions. Julian advises C-suite leaders who seek to optimize their organizations as workforce entities for transformational impact. Leveraging his expertise across education, workforce, and economic development, Julian has led groundbreaking efforts that have profoundly shaped the field.
</p><p class="para mb-10">Prior to forming J. Alssid Associates, Julian served as Vice President of Workforce Partnerships at the Community College of Rhode Island (CCRI), where he partnered with Governor Gina Raimondo, her leadership team, and industry leaders to align CCRI’s offerings with the economic and workforce development priorities of the state. Julian transformed CCRI’s workforce operation into a key driver of workforce and economic development in the region, serving over 25,000 students annually.
At Southern New Hampshire University, Julian helped design College for America (CfA), a groundbreaking competency-based education model. Drawing on his extensive network, Julian built partnerships with over 100 innovative corporate leaders that led to the establishment of CfA as a proof of concept for competency- and skills-based approaches to sourcing and training talent. Prior to SNHU, Julian led Workforce Strategy Center for over 15 years where he significantly influenced workforce policy and practice across over 23 states and the federal government. He is known for building and popularizing the career pathways framework. 
</p><p class="para mb-10">On a practitioner level, Julian established the workforce development arm of the Partnership for New York City, a nonprofit membership organization consisting of a select group of business leaders and employers from corporate, investment, and entrepreneurial firms. Julian engaged those leaders and their organizations in major partnerships with New York’s K-12 and postsecondary systems. He also developed an acclaimed small business management training program that presaged the Goldman Sachs 10,000 Small Businesses initiative at LaGuardia Community College. As advisor to New York City's Mayor, Julian also staffed the blue-ribbon commission that established the High School Institute for Law and Social Justice, one of the nation's first high school career academy models. Julian also served as an Adjunct Lecturer at LaGuardia Community College, and as a teacher at City-As-School, one of the nation’s leading experiential learning model for high school students.
</p><p class="para mb-10">Julian speaks and writes extensively on crafting effective workforce systems. He co-hosts Work Forces, a podcast exploring the future of work and learning. Julian has a bachelor's degree from Boston University, graduating magna cum laude with distinction.                       </p>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</section>
<StaticFooter />

    </>
  );
}

export default Team;