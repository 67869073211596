import { Link } from "react-router-dom";
import twitter from "../../assets/static/img/x-twitter.svg";

function StaticFooter() {
  return (
    <>

      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="col-md-2 f-right mobile">
                <a class="text-white" href="https://whatisreset.com/privacy.html">Privacy Policy</a>
              </div>
              <div class="footer-social col-md-4 f-right mobile">
                <ul class="list-unstyled text-white">
                  <li><Link class="wow fadeInUp" aria-label="Facebook" target="_blank" to="https://www.facebook.com/profile.php?id=61558478817292"><i aria-hidden="true" class="fab fa-facebook-f"></i></Link></li>
                  <li><Link class="wow fadeInDown" aria-label="Twitter" target="_blank" to="https://twitter.com/whatisreset"><img src={twitter} class="twitter-icon" alt="Thumb" /></Link></li>
                  <li><Link class="wow fadeInDown" aria-label="Linkedin" target="_blank" to="https://www.linkedin.com/in/whatisreset/"><i aria-hidden="true" class="fab fa-linkedin-in"></i></Link></li>
                  <li><Link class="wow fadeInUp" aria-label="Instagram" target="_blank" to="https://www.instagram.com/whatisreset"><i aria-hidden="true" class="fab fa-instagram"></i></Link></li>
                  <li><Link class="wow fadeInDown" aria-label="Pinterest" target="_blank" to="https://in.pinterest.com/whatisreset"><i aria-hidden="true" class="fab fa-pinterest-p"></i></Link></li>
                  <li><Link class="wow fadeInDown" aria-label="Medium" target="_blank" to="https://medium.com/@whatisreset"><i aria-hidden="true" class="fab fa-medium"></i></Link></li>
                </ul>
              </div>

              <p class="col-md-6 company-about fadeIn text-white f-left mobile">&copy; 2024 Integrated Diagnostic System Inc.</p>
            </div>
          </div>
        </div>
      </footer>


    </>
  );
}

export default StaticFooter;