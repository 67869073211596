import React, { useState, useEffect } from 'react';
import StaticHeader from "./StaticHeader";
import StaticFooter from "./StaticFooter";
import { API_BASE_URL, GET_THEME_LIST } from '../../Constants';
import loading from "../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";

function Themes() {
  const [themeList, setThemeList] = useState();
  const [left, setLeft] = React.useState([]);
  const [alertMsg, setAlertMsg] = useState(false);
  useEffect(() => {
    setAlertMsg(true);
    fetch(API_BASE_URL+GET_THEME_LIST)
    .then((response) => {
      setAlertMsg(false);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      setAlertMsg(false);
      setThemeList(data.data)   
    })
    .catch((error) => {
      setAlertMsg(false);
      setThemeList(null);
      console.error(error);
    });
  }, []);
 
  const selectTheme = (item) => {
    localStorage.setItem('selectedThemeId', item.themeId);
    localStorage.setItem('themeName', item.themeName);
    localStorage.setItem('description', item.description);
    window.location.href = '/theme/packages';
  }

  return (
    <>
 <StaticHeader />
 <section class="services sections-bg">
      <div class="container aos-init aos-animate" data-aos="fade-up" id="themes">
        <div class="section-header text-center">
          <h1 class="heading">Our Themes</h1>
          <p>Our themes are organized into packages, which are themselves organized into assessments. The price of a package includes the core assessments for that package, the assessments in the root cause package associated with each core assessment, a detailed report of your results, and recommendations for appropriate resources to help you in your efforts at self-improvement.</p>
        </div>
        {alertMsg ?
                <SweetAlert
        title=""
        timeout={10000}
        showConfirm={false}
        style={{ width: "200px", height: "100px" }}
      > <img src={loading} width="50px" height="50px"/>
      </SweetAlert>
      :<></>}
        <div class="row gy-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100" id="themeDiv">
        {(themeList ? themeList : left).map((value,index) => (
        <div class="col-lg-4 col-md-6 mt-30">
    	<div class="card service-item">
    	<div style={{height:"200px"}}>
    	<img class="card-img-top img-fluid" src={value.themeImageUrl} alt="Card image" style={{width:"100%"}} />
    	</div>
    	<div class="card-body">
    	<h3 class="themeHead">{value.themeName}</h3>
    	<p class="card-text"><div dangerouslySetInnerHTML={{ __html: value.description }}></div></p>
    	<button key={index} class="readmore stretched-link readMoreBtn" onClick={() => selectTheme(value)} >Read more<i class="fa fa-arrow-right ml-5px"></i></button>
    	</div>
    	</div>
    	</div>
)
)}

        </div>

      </div>
      
         
    </section>
<StaticFooter />

    </>
  );
}

export default Themes;