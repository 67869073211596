import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

function Footer() {
  const location = useLocation().pathname;

  return(
  <>
  {(location === "/" || location === "/about" || location === "/howitworks" || location === "/themes"
            || location === "/theme/packages" || location === "/distinctiveness" || location === "/team" || location === "/contactus"
            || location === "/privacy") ? "" :
  <div id="kt_app_footer" className="app-footer">
        <div className="app-container  container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3  justify-content-center">
<div className="order-2 order-md-1 footer-text">
    <span className="me-1 font-10">2024&copy;</span>
    <Link to="/overview" className="text-black">Integrated Diagnostic System Inc. All rights reserved.</Link>
</div>

       </div>
</div>
}
  </>
  );
}

export default Footer;